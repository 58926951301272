body {
  margin: 0;
  font-family:'Unbounded', sans-serif;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: black;
}

.App {
  font-family:'Unbounded', sans-serif;
  font-weight: 200;
  height: 100%;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.rightSide {
    padding-bottom: 10vh;
    margin-top: 5vh;
    width: 100%;
    height: 84vh;
}

.informations {
    width: 75%;
    height: 84vh;
    margin-left: 5vh;
    margin-right: 5vh;
    z-index: 99;
    font-weight: 100;
    color: rgba(255, 255, 255, 0.75);
    letter-spacing: 4px;
    -webkit-filter: drop-shadow(3px 3px 2px rgb(0, 0, 0));
            filter: drop-shadow(3px 3px 2px rgb(0, 0, 0));
}

.informationsText {
    padding-top: 3vh;
    font-weight: 200;
    font-size: small;
    -webkit-filter: drop-shadow(3px 3px 2px rgb(0, 0, 0));
            filter: drop-shadow(3px 3px 2px rgb(0, 0, 0));
}

.screenshot-preview {
    margin: 3vh;
}

.support {
    font-family: 'Unbounded' !important;
    padding: 2vh;
    height: 100vh;
    font-size: small;
    font-weight: 100;
    overflow: auto;
}

.appStoreGooglePlay {
    display: flex;
    justify-content: center;
}

img {
    width: 110px;
}

.leftTitle {
    letter-spacing: 3px;
    font-size: larger;
    font-weight: 300;
}

.downloadText {
    padding: 10px 0px;
    letter-spacing: 3px;
    text-align: center;
}

.pageHeader {
    font-weight: 200;
}

.features {
    margin-left: 2.5vh;
    margin-right: 2.5vh;
    margin-top: 7vh;
    letter-spacing: 2px;
    height: 100vh;
    overflow: auto;
}

.featuresText {
    word-spacing: 2px;
    padding: 2vh;
    font-size: x-small;
    overflow: auto;
    
    font-weight: 100;
    line-height: 2.20vh;
}

.featureListHeader {
    font-size: medium;
    margin-bottom: 2vh;
}

.features li {
    padding-bottom: 10px;
}

.featurePoint {
    margin-bottom: 2px;
    font-weight: 400;
}

.privacy {
    margin-top: 8vh;
    margin-left: 2.5vh;
    margin-right: 2.5vh;
    letter-spacing: 2px;

}

.privacyText {
    padding: 2vh;
    height: 75vh;
    font-size: x-small;
    font-weight: 100;
    overflow: auto;
}

@media only screen and (min-width: 359px) and (max-width: 812px) {
    .support {
        align-items: center;
    }

    .informations {
        color: white;
        font-weight: 300;
    }

    .informationsText {
        font-size: x-small;
    }

    .appStoreGooglePlay {
        padding-top: 3vh;
    }

    .logoContainer img {
        display: block;
        max-width: 100%;
    }

    rightContent {
        background-image: url(/static/media/background-right.2d6202b3.jpg);
        background-size: 550%;
        background-position: 85% 30%;
        -webkit-filter: brightness(75%);
                filter: brightness(75%);
        height: unset;
    }

    rightContent::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
}
.leftContent {
    width: 100%;
    height: 100vh;
    background-image: url(/static/media/background-right.2d6202b3.jpg);
    -webkit-filter: brightness(75%);
            filter: brightness(75%);
    background-size: 250vh;
    background-position: 75% 45%;
    position: relative;
}

.leftContent::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0,0,0,1), transparent);
}

@media only screen and (min-device-width: 359px) and (max-device-width: 812px) {
    .leftContent {
        display: none;
    }    
}
.windData {    
    width: 25vh;
    padding-bottom: 3vh;
    font-weight: 300;
    color: #FFFFFF;
    -webkit-filter: drop-shadow(3px 3px 2px rgb(0, 0, 0));
            filter: drop-shadow(3px 3px 2px rgb(0, 0, 0));
}

.windDataDivider {    
    border-width: 0.05px !important;
    border-color: white !important;
}

.spotName {
    padding: 5px;
}

.windSpeed {
    padding: 5px;
}
.windDataContainer {
    position: relative;
    z-index: 9999;
    padding-top: 15vh;
    color: #828282;
    display: grid;
    justify-items: center;
}
.appbar {
    position: fixed;
    z-index: 999;
    align-self: center;
    font-family: 'Unbounded';
    font-weight: 100;
    font-size: 2vh;
    border-bottom-color: #7dccff;
    border-bottom-style: solid;
    border-bottom-color: #353535;
    border-bottom-width: 0.25px;
}

.headerTitle {
    padding: 5px 10px;
    letter-spacing: 5px;
    color: #7dccff;
    text-transform: lowercase !important;
}

.headerDivider {
    border-width: 0.05px !important;
    border-color: #fff !important;
}

.headerDividerContainer {
    height: 50%;
}

.headerMenuItems {
    padding: 5px 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: #9e9e9e;
}

.menuItem.MuiButton-root {
    font-size: small;
    letter-spacing: 3px;
    text-transform: lowercase;
    font-family: 'Unbounded';
    font-weight: 100;
}

.menuItem:hover {
    color: #fff;
}

@media only screen and (min-width: 359px) and (max-width: 812px) {
    .headerMenuItems {
        display: none;
    }
    .headerDivider {
        display: none;
    }

    .headerTitle {
        padding: unset;
    }
}

.contentGrid {
    position: relative;
}

.linearGray {
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5956757703081232) 0%, rgba(24, 24, 24, 0.01) 100%);
}
