.windData {    
    width: 25vh;
    padding-bottom: 3vh;
    font-weight: 300;
    color: #FFFFFF;
    filter: drop-shadow(3px 3px 2px rgb(0, 0, 0));
}

.windDataDivider {    
    border-width: 0.05px !important;
    border-color: white !important;
}

.spotName {
    padding: 5px;
}

.windSpeed {
    padding: 5px;
}