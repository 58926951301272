body {
  background-color: black;
}

.App {
  font-family:'Unbounded', sans-serif;
  font-weight: 200;
  height: 100%;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
