.leftContent {
    width: 100%;
    height: 100vh;
    background-image: url(../../images/background-right.jpg);
    filter: brightness(75%);
    background-size: 250vh;
    background-position: 75% 45%;
    position: relative;
}

.leftContent::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0,0,0,1), transparent);
}

@media only screen and (min-device-width: 359px) and (max-device-width: 812px) {
    .leftContent {
        display: none;
    }    
}