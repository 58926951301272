.rightSide {
    padding-bottom: 10vh;
    margin-top: 5vh;
    width: 100%;
    height: 84vh;
}

.informations {
    width: 75%;
    height: 84vh;
    margin-left: 5vh;
    margin-right: 5vh;
    z-index: 99;
    font-weight: 100;
    color: rgba(255, 255, 255, 0.75);
    letter-spacing: 4px;
    filter: drop-shadow(3px 3px 2px rgb(0, 0, 0));
}

.informationsText {
    padding-top: 3vh;
    font-weight: 200;
    font-size: small;
    filter: drop-shadow(3px 3px 2px rgb(0, 0, 0));
}

.screenshot-preview {
    margin: 3vh;
}

.support {
    font-family: 'Unbounded' !important;
    padding: 2vh;
    height: 100vh;
    font-size: small;
    font-weight: 100;
    overflow: auto;
}

.appStoreGooglePlay {
    display: flex;
    justify-content: center;
}

img {
    width: 110px;
}

.leftTitle {
    letter-spacing: 3px;
    font-size: larger;
    font-weight: 300;
}

.downloadText {
    padding: 10px 0px;
    letter-spacing: 3px;
    text-align: center;
}

.pageHeader {
    font-weight: 200;
}

.features {
    margin-left: 2.5vh;
    margin-right: 2.5vh;
    margin-top: 7vh;
    letter-spacing: 2px;
    height: 100vh;
    overflow: auto;
}

.featuresText {
    word-spacing: 2px;
    padding: 2vh;
    font-size: x-small;
    overflow: auto;
    
    font-weight: 100;
    line-height: 2.20vh;
}

.featureListHeader {
    font-size: medium;
    margin-bottom: 2vh;
}

.features li {
    padding-bottom: 10px;
}

.featurePoint {
    margin-bottom: 2px;
    font-weight: 400;
}

.privacy {
    margin-top: 8vh;
    margin-left: 2.5vh;
    margin-right: 2.5vh;
    letter-spacing: 2px;

}

.privacyText {
    padding: 2vh;
    height: 75vh;
    font-size: x-small;
    font-weight: 100;
    overflow: auto;
}

@media only screen and (min-width: 359px) and (max-width: 812px) {
    .support {
        align-items: center;
    }

    .informations {
        color: white;
        font-weight: 300;
    }

    .informationsText {
        font-size: x-small;
    }

    .appStoreGooglePlay {
        padding-top: 3vh;
    }

    .logoContainer img {
        display: block;
        max-width: 100%;
    }

    rightContent {
        background-image: url(../../images/background-right.jpg);
        background-size: 550%;
        background-position: 85% 30%;
        filter: brightness(75%);
        height: unset;
    }

    rightContent::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
    }
}