.appbar {
    position: fixed;
    z-index: 999;
    align-self: center;
    font-family: 'Unbounded';
    font-weight: 100;
    font-size: 2vh;
    border-bottom-color: #7dccff;
    border-bottom-style: solid;
    border-bottom-color: #353535;
    border-bottom-width: 0.25px;
}

.headerTitle {
    padding: 5px 10px;
    letter-spacing: 5px;
    color: #7dccff;
    text-transform: lowercase !important;
}

.headerDivider {
    border-width: 0.05px !important;
    border-color: #fff !important;
}

.headerDividerContainer {
    height: 50%;
}

.headerMenuItems {
    padding: 5px 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: #9e9e9e;
}

.menuItem.MuiButton-root {
    font-size: small;
    letter-spacing: 3px;
    text-transform: lowercase;
    font-family: 'Unbounded';
    font-weight: 100;
}

.menuItem:hover {
    color: #fff;
}

@media only screen and (min-width: 359px) and (max-width: 812px) {
    .headerMenuItems {
        display: none;
    }
    .headerDivider {
        display: none;
    }

    .headerTitle {
        padding: unset;
    }
}
